<template>
  <div>
    <div>
      <div class="eidt-item edit-content">
        <ueditor-wrap
          v-model="editForm.data.ContentHtml"
          :config="{ initialFrameWidth: '100%' }"
        ></ueditor-wrap>
      </div>
      <div class="eidt-item edit-field">
        <div class="title">汇总</div>
        <div v-for="(key, value) in zCol" :key="value" class="field-item">
          {{ key }}
        </div>
        <div class="title">明细</div>
        <div v-for="(key, value) in xCol" :key="value" class="field-item">
          {{ key }}
        </div>
      </div>
      <div class="eidt-item edit-template">
        <edit-form ref="editForm" :formConfig="editForm"></edit-form>
        <div class="tip">
          注：
          <div>1 单位使用毫米。</div>
          <div>2 边距存在本机。</div>
          <div>3 '固定头尾'选择打勾，[小计]才有效。</div>
        </div>
      </div>
    </div>
    <div class="save-block">
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>
<script>
import { submit } from "@/api/base";

export default {
  name: "editOtherTemplate",
  data() {
    return {
      routeParam: {},
      zCol: {},
      xCol: {},
      editForm: {
        url: "/api/invoiceTemplate/edit",
        saveUrl: "/api/invoiceTemplate/doEdit",
        isInline: false,
        labelWidth: "85px",
        tipWidth: "60px",
        items: [
          {
            type: "input",
            label: "自增ID",
            prop: "InvoiceTemplateID",
            dataType: "number",
            width: "350px",
            isHidden: true,
          },
          {
            type: "input",
            label: "名称",
            prop: "InvoiceTemplateName",
            dataType: "string",
            width: "350px",
            isRequired: true,
            trigger: "blur",
          },
          {
            type: "select",
            label: "模板类型",
            prop: "Type",
            dataType: "number",
            width: "350px",
            optionField: "InvoiceTemplateTypeList",
            isRequired: true,
            trigger: "change",
            disabled: true,
          },
          {
            type: "switch-int",
            label: "是否启用",
            prop: "EnableFlag",
            dataType: "number",
            width: "350px",
          },

          {
            type: "switch-int",
            label: "是否默认",
            prop: "DefaultFlag",
            dataType: "number",
            width: "350px",
          },
          {
            type: "input-int",
            label: "纸张宽度",
            prop: "InvoiceWidth",
            dataType: "number",
            width: "350px",
            isRequired: true,
            trigger: "blur",
          },
          {
            type: "input-int",
            label: "纸张高度",
            prop: "InvoiceHeight",
            dataType: "number",
            width: "350px",
            isRequired: true,
            trigger: "blur",
          },
          {
            type: "input-int",
            label: "上边距",
            prop: "TemplateTop",
            dataType: "number",
            width: "350px",
          },
          {
            type: "input-int",
            label: "下边距",
            prop: "TemplateBottom",
            dataType: "number",
            width: "350px",
          },
          {
            type: "input-int",
            label: "左边距",
            prop: "TemplateLeft",
            dataType: "number",
            width: "350px",
          },
          {
            type: "switch-int",
            label: "固定头尾",
            prop: "IsPage",
            dataType: "number",
            width: "350px",
          },
        ],
        data: {
          ContentHtml: "",
        },
      },
    };
  },
  mounted() {
    this.routeParam = {
      ...(this.$route.query || {}),
      ...(this.$route.params || {}),
    };
  },
  activated() {
    var param = {
      ...(this.$route.query || {}),
      ...(this.$route.params || {}),
    };
    if (Object.keys(param).length > 0 && this.routeParam != param) {
      this.routeParam = param;
    }
  },
  watch: {
    routeParam: {
      deep: true,
      handler() {
        this.handleQuery();
      },
    },
  },
  methods: {
    async handleQuery() {
      const { data } = await submit(this.editForm.url, this.routeParam);
      this.editForm.data = data;
      this.zCol = data.InitData.ZCol;
      this.xCol = data.InitData.XCol;
      const { items, rules } = this.getFormRules(
        this.editForm.items,
        data.InitData
      );
      this.$set(this.editForm, "items", items);
      this.$set(this.editForm, "rules", rules);
    },
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          await submit(this.editForm.saveUrl, this.editForm.data);
          this.$baseMessage("保存成功", "success");
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.eidt-item {
  float: left;
}

.edit-content {
  width: 68%;
}

.edit-field {
  width: 10%;
  border: 1px solid #ccc;
  height: 600px;
  overflow-y: auto;

  .title {
    background: #edf2fa;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .field-item {
    height: 30px;
    border-bottom: 1px solid #dbe8ff;
    line-height: 30px;
    margin: 4px;
    padding-left: 5px;
  }
}

.edit-template {
  width: 20%;
  height: 600px;
  border: 1px solid #ccc;
  padding-top: 10px;

  .tip{
    border-top: 1px solid #ddd;
    color: #7189F7;
    padding: 10px;
  }
}

.save-block {
  text-align: right;
  clear: both;
  padding: 10px 20px 0 0;

  .el-button {
    width: 150px;
  }
}
</style>